import styled from 'styled-components'

import { Card } from '../../../shared/components/card'
import { CercleCircle } from '../../../shared/components/cercle-circle'
import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodyMediumRegularStyles,
  Heading5Styles,
} from '../../../shared/components/typography'

export const StCard = styled(Card)`
  flex-direction: column-reverse;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex-direction: row;
  }
`

export const StTable = styled.table`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  padding-right: 0px;
  border-spacing: 0;
`

export const StLabelTd = styled.td`
  ${Heading5Styles}

  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

export const StValueTd = styled.td`
  ${BodyMediumRegularStyles}

  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

export const StDivider = styled.tr.attrs({
  children: (
    <td colSpan={2}>
      <div />
    </td>
  ),
})`
  & td {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space5} 0px;
  }

  & div {
    background: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.Colors.Neutrals.Dark} 0%, rgba(75, 75, 75, 0) 100%)`};
    height: 1px;

    @media screen and (min-width: ${({ theme }) =>
        theme.UI.BreakpointPx.Tablet}) {
      margin-right: -40px;
    }
  }

  &:last-of-type {
    display: none;
  }
`

export const StImageContainer = styled.div`
  position: relative;

  height: 280px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    height: 100%;
    flex: 1;
  }

  overflow: hidden;
`

export const StCercleCircle = styled(CercleCircle).attrs({ variation: 3 })`
  width: 180%;
  height: auto;
  display: block;

  top: ${({ theme }) => theme.UI.SpacingPx.Space1};
  left: ${({ theme }) => theme.UI.SpacingPx.Space1};

  position: absolute;

  z-index: 1;
`

export const StImage = styled(StoryblokServiceImage)`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;

  z-index: 2;
`
