import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { useState } from 'react'

import { Button } from '../../../shared/components/button'
import { Loader } from '../../../shared/components/loader'
import { Modal } from '../../../shared/components/modal'
import {
  BodyMediumBold,
  BodyMediumRegular,
} from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { useCards } from '../../hooks/useCards'
import { AccountCardDelete } from '../account-card-delete'
import { AccountCardSettings } from '../account-card-settings'
import { StLoadingContainer } from '../account-card-settings/AccountCardSettings.styled'
import { AccountDataCard } from '../account-data-card'

import {
  StBalanceContainer,
  StCard,
  StCardsContainer,
  StDataContainer,
  StDeleteIcon,
} from './AccountBalance.styled'

import type { MyCercleCard } from '../../types/myCercleCards'

interface AccountBalanceProps {
  title: string
  backgroundImage?: string
  isLoading?: boolean
  eventIdPlaypass?: number
}

export const AccountBalance = ({
  title,
  backgroundImage,
  isLoading,
  eventIdPlaypass,
}: AccountBalanceProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { cards, loading, deleteCard, fetchCards } = useCards()

  const [isAddModalOpen, setisAddModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [cardToDelete, setCardToDelete] = useState<MyCercleCard | undefined>()

  const handleCloseAddModal = () => {
    fetchCards()
    setisAddModalOpen(false)
  }

  const handleOpenDeleteCardModal = (card: MyCercleCard) => {
    setCardToDelete(card)
    setIsDeleteModalOpen(true)
  }

  const handleDeleteCard = () => {
    if (!cardToDelete) return
    deleteCard(cardToDelete?.number)
    setIsDeleteModalOpen(false)
  }

  if (isLoading) {
    return (
      <StLoadingContainer>
        <Loader />
      </StLoadingContainer>
    )
  }

  return (
    <>
      <AccountDataCard
        title={title}
        icon={faCreditCard}
        backgroundImage={backgroundImage}
      >
        <StDataContainer>
          {!cards ||
            (cards.length === 0 ? (
              <>
                <BodyMediumRegular>
                  {t('account_card_no_cards')}
                </BodyMediumRegular>
                <Button
                  gaEventLabel="press_open_my_cercle_card_modal"
                  suffixIcon={faArrowRight}
                  onClick={() => setisAddModalOpen(true)}
                >
                  {t('account_card_add_card_button')}
                </Button>
              </>
            ) : loading ? (
              <StLoadingContainer>
                <Loader />
              </StLoadingContainer>
            ) : (
              <>
                <>
                  <StCardsContainer>
                    {cards.map((card, index) => (
                      <StCard key={index}>
                        <BodyMediumBold>{card.name}</BodyMediumBold>
                        <StBalanceContainer>
                          <BodyMediumRegular>€{card.balance}</BodyMediumRegular>
                          <StDeleteIcon
                            icon={faTrash}
                            onClick={() => handleOpenDeleteCardModal(card)}
                          />
                        </StBalanceContainer>
                      </StCard>
                    ))}
                  </StCardsContainer>
                  <Button
                    gaEventLabel="press_open_my_cercle_card_modal"
                    suffixIcon={faArrowRight}
                    onClick={() => setisAddModalOpen(true)}
                  >
                    {t('account_card_add_saldo_button')}
                  </Button>
                </>
              </>
            ))}
        </StDataContainer>
      </AccountDataCard>
      <Modal open={isAddModalOpen} onClose={() => handleCloseAddModal()}>
        <AccountCardSettings
          backgroundImage={backgroundImage}
          eventIdPlaypass={eventIdPlaypass}
        />
      </Modal>
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <AccountCardDelete
          card={cardToDelete}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteCard}
        />
      </Modal>
    </>
  )
}
