import { addMinutes, endOfDay, format, subDays } from 'date-fns'
import { useMemo } from 'react'
import { isTeamCercle } from 'utils'

import { MatchCalendarCard } from '../../../matches/components/match-calendar-card'
import { MatchHighlight } from '../../../matches/components/match-highlight'
import { MatchLiveCard } from '../../../matches/components/match-live-card'
import { MatchUpcoming } from '../../../matches/components/match-upcoming'
import { useMatchStatsQuery } from '../../../matches/hooks/useMatchStatsQuery'
import { setTimeOnDate } from '../../../matches/utils/setTimeOnDate'
import { Loader } from '../../../shared/components/loader'
import { useSWR } from '../../../shared/hooks/useSWR'
import { useStoryblokTranslationsContext } from '../../context/translations-provider'
import { useStoryblokStories } from '../../hooks/useStoryblokStories'
import { useTranslations } from '../../hooks/useTranslations'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'
import { getMatchesContent } from '../../utils/getMatchesContent'

import {
  StContainer,
  StFourth,
  StHalf,
  StLoadingContainer,
} from './MatchWidget.styled'

import type { StoryblokMatch } from '../../content-types/match'
import type { StoryblokTeam } from '../../content-types/team'
import type { ISbStoryData } from '@storyblok/react'
import type { MatchInformation, StoryblokImage, StoryblokLink } from 'types'

const getNotCercleTeam = (
  homeTeam: string | ISbStoryData<StoryblokTeam> | undefined,
  awayTeam: string | ISbStoryData<StoryblokTeam> | undefined
) => {
  if (
    typeof homeTeam !== 'string' &&
    homeTeam?.content &&
    !isTeamCercle(homeTeam?.content?.name)
  ) {
    return homeTeam
  }
  if (
    typeof awayTeam !== 'string' &&
    awayTeam?.content &&
    !isTeamCercle(awayTeam?.content?.name)
  ) {
    return awayTeam
  }
  return undefined
}

const normalizeMatchContent = (content: StoryblokMatch[]) => {
  return content.map((match) => {
    const otherTeam = getNotCercleTeam(match.home_team, match.away_team)

    return {
      id: match?._uid,
      teamLogo: otherTeam?.content?.logo,
      teamName: otherTeam?.content?.name,
      teamNameShort: otherTeam?.content?.short_name,
      matchDate: match?.matchday || '',
      matchTime: match?.time,
      ticketLink: {
        href: getHrefFromStoryblokLink(match.tickets) ?? '',
      },
      playingHome:
        typeof match?.home_team === 'string'
          ? false
          : otherTeam?.uuid !== match.home_team?.uuid,
    }
  })
}

interface StoryblokMatchWidgetProps {
  blok: {
    _uid: string
    background: StoryblokImage
    highlighted_match_title?: string
    highlighted_match?: ISbStoryData<StoryblokMatch>
    highlighted_match_link?: StoryblokLink
    next_match_title?: string
    sponsor_image?: StoryblokImage
    sponsor_link?: StoryblokLink
    sponsor_live_image?: StoryblokImage
    sponsor_live_link?: StoryblokLink
    future_matches_title?: string
    future_matches_link?: StoryblokLink
  }
  relations?: ISbStoryData<StoryblokTeam>[]
}

export const MatchWidget = ({ blok }: StoryblokMatchWidgetProps) => {
  const { cercleTeam, highlightedTeam } = useMemo(() => {
    if (
      isTeamCercle(
        (
          blok.highlighted_match?.content
            ?.home_team as ISbStoryData<StoryblokTeam>
        )?.content?.name,
        (
          blok.highlighted_match?.content
            ?.home_team as ISbStoryData<StoryblokTeam>
        )?.content?.short_name
      )
    ) {
      return {
        cercleTeam: blok.highlighted_match?.content
          ?.home_team as ISbStoryData<StoryblokTeam>,
        highlightedTeam: blok.highlighted_match?.content
          ?.away_team as ISbStoryData<StoryblokTeam>,
      }
    } else if (
      isTeamCercle(
        (
          blok.highlighted_match?.content
            ?.away_team as ISbStoryData<StoryblokTeam>
        )?.content?.name,
        (
          blok.highlighted_match?.content
            ?.away_team as ISbStoryData<StoryblokTeam>
        )?.content?.short_name
      )
    ) {
      return {
        highlightedTeam: blok.highlighted_match?.content
          ?.home_team as ISbStoryData<StoryblokTeam>,
        cercleTeam: blok.highlighted_match?.content
          ?.away_team as ISbStoryData<StoryblokTeam>,
      }
    }

    return {}
  }, [
    blok.highlighted_match?.content?.home_team,
    blok.highlighted_match?.content?.away_team,
  ])

  const highlightedMatchStatsQueryParameters = useMatchStatsQuery({
    homeContestantId: (
      blok.highlighted_match?.content?.home_team as ISbStoryData<StoryblokTeam>
    )?.content?.stats_data_id,
    awayContestantId: (
      blok.highlighted_match?.content?.away_team as ISbStoryData<StoryblokTeam>
    )?.content?.stats_data_id,
    matchDay: blok.highlighted_match?.content?.matchday || '',
  })

  const {
    data: highLightedMatchData,
    isLoading: isHighlightedMatchLoading,
    error: highlightedMatchError,
  } = useSWR<MatchInformation>(
    highlightedMatchStatsQueryParameters
      ? `/stats/matches?${highlightedMatchStatsQueryParameters}`
      : ''
  )

  const { translations, locale } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { stories, isLoading: isStoryblokDataLoading } =
    useStoryblokStories<StoryblokMatch>({
      content_type: 'match',
      starts_with: 'configuration/matches/a-team',
      resolve_relations: 'match.away_team,match.home_team',
      sort_by: 'content.matchday:asc',
      filter_query: {
        matchday: {
          gt_date: format(endOfDay(subDays(new Date(), 1)), 'yyyy-MM-dd HH:mm'),
        },
      },
      per_page: 6,
    })

  const matchContent = getMatchesContent(stories, false)

  const upcomingMatchContent =
    matchContent.length > 0 ? matchContent[0] : undefined
  const secondUpcomingMatchContent =
    matchContent.length > 1 ? matchContent[1] : undefined

  const upcomingHomeTeam =
    typeof upcomingMatchContent?.home_team === 'string'
      ? undefined
      : upcomingMatchContent?.home_team?.content
  const upcomingAwayTeam =
    typeof upcomingMatchContent?.away_team === 'string'
      ? undefined
      : upcomingMatchContent?.away_team?.content

  const secondUpcomingHomeTeam =
    typeof secondUpcomingMatchContent?.home_team === 'string'
      ? undefined
      : secondUpcomingMatchContent?.home_team?.content
  const secondUpcomingAwayTeam =
    typeof secondUpcomingMatchContent?.away_team === 'string'
      ? undefined
      : secondUpcomingMatchContent?.away_team?.content

  const futureMatchContent = useMemo(() => {
    return matchContent.length > 1
      ? normalizeMatchContent(matchContent.slice(2, 6))
      : []
  }, [matchContent])

  const upcomingMatchQueryParameters = useMatchStatsQuery({
    homeContestantId: upcomingHomeTeam?.stats_data_id,
    awayContestantId: upcomingAwayTeam?.stats_data_id,
    matchDay: upcomingMatchContent?.matchday || '',
  })
  const secondUpcomingMatchQueryParameters = useMatchStatsQuery({
    homeContestantId: secondUpcomingHomeTeam?.stats_data_id,
    awayContestantId: secondUpcomingAwayTeam?.stats_data_id,
    matchDay: secondUpcomingMatchContent?.matchday || '',
  })

  const {
    data: upcomingMatchStatsData,
    isLoading: isUpcomingMatchLoading,
    error: upcomingMatchError,
  } = useSWR<MatchInformation>(
    upcomingMatchQueryParameters
      ? `/stats/matches?${upcomingMatchQueryParameters}`
      : ''
  )
  const {
    data: secondUpcomingMatchStatsData,
    isLoading: isSecondUpcomingMatchLoading,
    error: secondUpcomingMatchError,
  } = useSWR<MatchInformation>(
    secondUpcomingMatchQueryParameters
      ? `/stats/matches?${secondUpcomingMatchQueryParameters}`
      : ''
  )

  const isUpcomingMatchedAlreadyPlayed = useMemo(() => {
    const matchStatus = upcomingMatchStatsData?.status

    if (matchStatus) {
      return (
        matchStatus === 'Cancelled' ||
        matchStatus === 'Suspended' ||
        matchStatus === 'Played'
      )
    }

    const matchDate = upcomingMatchContent?.matchday ?? ''
    const matchStartTime = upcomingMatchStatsData?.startDateTime
      ? format(new Date(upcomingMatchStatsData.startDateTime), 'HH:mm')
      : upcomingMatchContent?.time

    const matchDay = new Date(matchDate.replace(' ', 'T'))
    const matchDayWithTime = setTimeOnDate(matchDay, matchStartTime)

    // Manually guesstimate match end as fallback when stats data doesn't come through
    return addMinutes(matchDayWithTime, 115).getTime() <= Date.now()
  }, [
    upcomingMatchContent?.matchday,
    upcomingMatchContent?.time,
    upcomingMatchStatsData?.startDateTime,
    upcomingMatchStatsData?.status,
  ])

  const upComingMatches = useMemo(() => {
    return isUpcomingMatchedAlreadyPlayed || !secondUpcomingMatchContent
      ? futureMatchContent
      : [
          ...normalizeMatchContent([secondUpcomingMatchContent]),
          ...futureMatchContent,
        ]
  }, [
    futureMatchContent,
    isUpcomingMatchedAlreadyPlayed,
    secondUpcomingMatchContent,
  ])

  if (
    (isHighlightedMatchLoading && !highlightedMatchError) ||
    (isUpcomingMatchLoading && !upcomingMatchError) ||
    isStoryblokDataLoading ||
    (isSecondUpcomingMatchLoading && !secondUpcomingMatchError)
  ) {
    return (
      <StLoadingContainer>
        <Loader />
      </StLoadingContainer>
    )
  }

  return (
    <StContainer>
      <StFourth>
        <MatchHighlight
          background={blok.background}
          title={blok.highlighted_match_title}
          teamName={highlightedTeam?.content?.name}
          link={{
            href: getHrefFromStoryblokLink(blok.highlighted_match_link) ?? '',
          }}
          linkText={t('report_and_photos')}
          clubLogo={highlightedTeam?.content.logo}
          homeTeamIsCercle={
            (
              blok.highlighted_match?.content
                ?.home_team as ISbStoryData<StoryblokTeam>
            )?.uuid === cercleTeam?.uuid
          }
          homeTeamGoals={
            highLightedMatchData?.score?.home === undefined
              ? blok.highlighted_match?.content?.home_team_goals
              : `${highLightedMatchData.score.home}`
          }
          awayTeamIsCercle={
            (
              blok.highlighted_match?.content
                ?.away_team as ISbStoryData<StoryblokTeam>
            )?.uuid === cercleTeam?.uuid
          }
          awayTeamGoals={
            highLightedMatchData?.score?.away === undefined
              ? blok.highlighted_match?.content?.away_team_goals
              : `${highLightedMatchData.score.away}`
          }
        />
      </StFourth>
      <StHalf>
        {upcomingMatchContent && upcomingMatchContent._uid && (
          <>
            {!isUpcomingMatchedAlreadyPlayed &&
              upcomingMatchStatsData?.status !== 'Playing' && (
                <MatchUpcoming
                  title={blok.next_match_title}
                  homeTeamName={upcomingHomeTeam?.name ?? ''}
                  homeTeamLogo={upcomingHomeTeam?.logo}
                  awayTeamName={upcomingAwayTeam?.name ?? ''}
                  awayTeamLogo={upcomingAwayTeam?.logo}
                  matchDate={upcomingMatchContent?.matchday ?? ''}
                  matchTime={
                    upcomingMatchStatsData?.startDateTime
                      ? format(
                          new Date(upcomingMatchStatsData.startDateTime),
                          'HH:mm'
                        )
                      : upcomingMatchContent?.time
                  }
                  sponsorImage={blok.sponsor_image}
                  sponsorLink={{
                    href: getHrefFromStoryblokLink(blok.sponsor_link) ?? '',
                  }}
                  ticketLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.tickets) ??
                      '',
                  }}
                  vipLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.vip) ?? '',
                  }}
                  infoLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.info) ??
                      '',
                  }}
                  daysShorthand={t('days_shorthand')}
                  locale={locale}
                />
              )}

            {!isUpcomingMatchedAlreadyPlayed &&
              upcomingMatchStatsData?.status === 'Playing' && (
                <MatchLiveCard
                  homeTeamName={upcomingHomeTeam?.name ?? ''}
                  homeTeamLogo={upcomingHomeTeam?.logo}
                  homeTeamScore={upcomingMatchStatsData.score?.home}
                  awayTeamName={upcomingAwayTeam?.name ?? ''}
                  awayTeamLogo={upcomingAwayTeam?.logo}
                  awayTeamScore={upcomingMatchStatsData.score?.away}
                  matchDate={upcomingMatchContent?.matchday ?? ''}
                  matchTime={
                    upcomingMatchStatsData?.startDateTime
                      ? format(
                          new Date(upcomingMatchStatsData.startDateTime),
                          'HH:mm'
                        )
                      : upcomingMatchContent?.time
                  }
                  sponsorImage={blok.sponsor_live_image}
                  sponsorLink={{
                    href:
                      getHrefFromStoryblokLink(blok.sponsor_live_link) ?? '',
                  }}
                  ticketLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.tickets) ??
                      '',
                  }}
                  vipLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.vip) ?? '',
                  }}
                  infoLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.info) ??
                      '',
                  }}
                />
              )}

            {isUpcomingMatchedAlreadyPlayed && (
              <MatchUpcoming
                title={blok.next_match_title}
                homeTeamName={secondUpcomingHomeTeam?.name ?? ''}
                homeTeamLogo={secondUpcomingHomeTeam?.logo}
                awayTeamName={secondUpcomingAwayTeam?.name ?? ''}
                awayTeamLogo={secondUpcomingAwayTeam?.logo}
                matchDate={secondUpcomingMatchContent?.matchday ?? ''}
                matchTime={
                  secondUpcomingMatchStatsData?.startDateTime
                    ? format(
                        new Date(secondUpcomingMatchStatsData.startDateTime),
                        'HH:mm'
                      )
                    : secondUpcomingMatchContent?.time
                }
                sponsorImage={blok.sponsor_image}
                sponsorLink={{
                  href: getHrefFromStoryblokLink(blok.sponsor_link) ?? '',
                }}
                ticketLink={{
                  href:
                    getHrefFromStoryblokLink(
                      secondUpcomingMatchContent?.tickets
                    ) ?? '',
                }}
                vipLink={{
                  href:
                    getHrefFromStoryblokLink(secondUpcomingMatchContent?.vip) ??
                    '',
                }}
                infoLink={{
                  href:
                    getHrefFromStoryblokLink(
                      secondUpcomingMatchContent?.info
                    ) ?? '',
                }}
                daysShorthand={t('days_shorthand')}
                locale={locale}
              />
            )}
          </>
        )}
      </StHalf>
      {upComingMatches && upComingMatches.length > 0 ? (
        <StFourth>
          <MatchCalendarCard
            title={blok.future_matches_title}
            matches={upComingMatches}
            allMatchesLink={{
              href: getHrefFromStoryblokLink(blok.future_matches_link) ?? '',
            }}
            allMatchesText={t('all_matches')}
            locale={locale}
          />
        </StFourth>
      ) : null}
    </StContainer>
  )
}
