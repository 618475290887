import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import { Heading4 } from '../../../shared/components/typography'

export const StContainer = styled.div`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    width: 200px;
  }
`

export const StClubLogo = styled(StoryblokServiceImage)`
  width: 48px;
  height: 52px;
`

export const StClubName = styled(Heading4)<{ variant: 'light' | 'dark' }>`
  text-align: center;
  color: ${({ variant, theme }) =>
    variant === 'light' ? theme.Colors.Neutrals.Black : 'inherit'};
`
