import styled from 'styled-components'

import { CercleCircle } from '../../../shared/components/cercle-circle'
import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  Heading3,
  BodySmallBold,
  BodySmallRegular,
  Heading4,
} from '../../../shared/components/typography'

export const StContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    padding: 0;
  }
`
export const StTitleContainer = styled.div`
  display: none;
  width: 100%;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  text-align: center;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const StTitleContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const StTitle = styled(BodySmallBold)`
  margin-right: auto;
`

export const StSponsorLink = styled.a`
  margin-left: auto;
`

export const StSponsorImage = styled(StoryblokServiceImage)`
  max-width: 150px;
  max-height: 36px;
  object-fit: contain;
  margin-left: auto;
`

export const StContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    flex-direction: column;
    padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
    display: none;
  }
`

export const StTeamContainer = styled.div`
  display: flex;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StClubLogo = styled(StoryblokServiceImage)`
  width: 40px;
  height: 40px;
`

export const StContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`

export const StTeam = styled(Heading4)``

export const StInfo = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`

export const StHomeLabel = styled.span`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space1};
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StFullCardContainer = styled.div`
  display: none;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  height: 100%;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: flex;
    flex-direction: column;
  }
`

export const StCercleCirlce = styled(CercleCircle)`
  position: absolute;
  bottom: 200px;
  left: 100px;
  transform: scale(2);
  fill: ${({ theme }) => theme.Colors.Neutrals.Darkest};
`

export const StResultContainer = styled.div`
  display: flex;
  z-index: 2;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space12} 0;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StMatchday = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;
  text-align: center;
`
export const StDate = styled(BodySmallBold)`
  opacity: 0.5;
`

export const StTimeContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Dark};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StTime = styled(Heading3)``

export const StButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
`
