import { isTeamCercle } from 'utils'

import { ButtonLink } from '../../../shared/components/button'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { getValidStoryblokDate } from '../../../storyblok/utils/checkStoryblokDate'
import { timestampToLocaleDatestring } from '../../utils/timestampToLocaleDate'
import { MatchClub } from '../match-club'
import { MatchCountdown } from '../match-countdown'
import { isValidLink } from '../match-live-card'

import {
  StClubLogo,
  StContainer,
  StContentContainer,
  StTeamContainer,
  StTitle,
  StTitleContainer,
  StContent,
  StTeam,
  StInfo,
  StHomeLabel,
  StFullCardContainer,
  StCercleCirlce,
  StResultContainer,
  StMatchday,
  StDate,
  StTimeContainer,
  StTime,
  StTitleContent,
  StButtonContainer,
  StSponsorImage,
  StSponsorLink,
} from './MatchUpcoming.styled'

import type { Hyperlink, StoryblokImage } from 'types'

interface MatchUpcomingProps {
  className?: string
  title?: string
  homeTeamName: string
  homeTeamLogo?: StoryblokImage
  awayTeamName: string
  awayTeamLogo?: StoryblokImage
  matchDate: string
  matchTime?: string
  sponsorImage?: StoryblokImage
  sponsorLink: Hyperlink
  ticketLink?: Hyperlink
  infoLink?: Hyperlink
  vipLink?: Hyperlink
  daysShorthand?: string
  locale?: string
}

export const MatchUpcoming = ({
  className,
  title,
  homeTeamName,
  homeTeamLogo,
  awayTeamName,
  awayTeamLogo,
  matchDate,
  matchTime,
  sponsorImage,
  sponsorLink,
  ticketLink,
  infoLink,
  vipLink,
  daysShorthand,
  locale,
}: MatchUpcomingProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const playingHome = isTeamCercle(homeTeamName)
  const highlightLogo = playingHome ? awayTeamLogo : homeTeamLogo

  const matchDateObject = getValidStoryblokDate(matchDate)

  return (
    <StContainer className={className}>
      <StTitleContainer>
        <StTitleContent>
          <StTitle>{title}</StTitle>
        </StTitleContent>
        <StTitleContent>
          <MatchCountdown
            matchDate={matchDate}
            matchTime={matchTime}
            daysShorthand={daysShorthand}
          />
        </StTitleContent>
        <StTitleContent>
          {sponsorImage &&
            (sponsorLink && sponsorLink.href !== '' ? (
              <StSponsorLink href={sponsorLink.href}>
                <StSponsorImage
                  image={sponsorImage}
                  sizeMobile={'150px'}
                  sizeTablet={'150px'}
                  sizeDesktop={'48px'}
                />
              </StSponsorLink>
            ) : (
              <StSponsorImage
                image={sponsorImage}
                sizeMobile={'150px'}
                sizeTablet={'150px'}
                sizeDesktop={'48px'}
              />
            ))}
        </StTitleContent>
      </StTitleContainer>
      <StContentContainer>
        <StTeamContainer>
          {highlightLogo && (
            <StClubLogo
              image={highlightLogo}
              sizeMobile={'150px'}
              sizeTablet={'150px'}
              sizeDesktop={'48px'}
            />
          )}
          <StContent>
            <StTeam>{playingHome ? awayTeamName : homeTeamName}</StTeam>
            {matchDateObject && (
              <StInfo>
                {timestampToLocaleDatestring(matchDateObject, locale ?? 'nl')},{' '}
                {matchTime}
                <StHomeLabel>{playingHome ? 'H' : 'A'}</StHomeLabel>
              </StInfo>
            )}
          </StContent>
        </StTeamContainer>
        {ticketLink && isValidLink(ticketLink) && (
          <ButtonLink
            variant="primary"
            prefixIcon="ticket"
            href={ticketLink.href}
          />
        )}
      </StContentContainer>
      <StFullCardContainer>
        <StResultContainer>
          <MatchClub name={homeTeamName} logo={homeTeamLogo} />
          <StMatchday>
            <StDate>
              {matchDateObject
                ? timestampToLocaleDatestring(matchDateObject, locale ?? 'nl')
                : ''}
            </StDate>
            <StTimeContainer>
              <StTime>{matchTime}</StTime>
            </StTimeContainer>
          </StMatchday>
          <MatchClub name={awayTeamName} logo={awayTeamLogo} />
        </StResultContainer>
        <StCercleCirlce variation={4} />
        <StButtonContainer>
          {ticketLink && isValidLink(ticketLink) && (
            <ButtonLink
              variant="primary"
              prefixIcon="ticket"
              href={ticketLink.href}
              size="small"
              gaEventLabel="press_widget_match_tickets"
            >
              {t('match_card_tickets')}
            </ButtonLink>
          )}

          {infoLink && isValidLink(infoLink) && (
            <ButtonLink
              variant="secondary"
              href={infoLink.href}
              size="small"
              gaEventLabel="press_widget_match_info"
            >
              {t('match_card_info')}
            </ButtonLink>
          )}
          {vipLink && isValidLink(vipLink) && (
            <ButtonLink
              variant="secondary"
              href={vipLink.href}
              size="small"
              gaEventLabel="press_widget_match_vip"
            >
              {t('match_card_vip')}
            </ButtonLink>
          )}
        </StButtonContainer>
      </StFullCardContainer>
    </StContainer>
  )
}
