import { storyblokEditable } from '@storyblok/react'

import { AccountBalance as AccountBalanceComponent } from '../../../mycercle/components/account-balance'
import { useStoryblokCercleGeneralSettings } from '../../hooks/useStoryblokCercleGeneralSettings'

export type AccountBalanceProps = {
  blok: {
    _uid: string
    title: string
    background_image?: {
      filename: string
    }
  }
}

export const AccountBalance = ({ blok }: AccountBalanceProps) => {
  //Get Playpass eventId through storyblok settings
  const { config, isLoading } = useStoryblokCercleGeneralSettings()

  return (
    <div {...storyblokEditable(blok)}>
      <AccountBalanceComponent
        title={blok.title}
        backgroundImage={blok.background_image?.filename}
        eventIdPlaypass={config?.content.event_id_playpass}
        isLoading={isLoading}
      />
    </div>
  )
}
