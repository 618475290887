import { storyblokEditable } from '@storyblok/react'
import { addHours, startOfDay } from 'date-fns'
import Dayjs from 'dayjs'
import { useMemo } from 'react'

import { MatchCard } from '../../../matches/components/match-card'
import { MatchResultCard } from '../../../matches/components/match-result-card'
import { Divider } from '../../../shared/components/divider'
import { Loader } from '../../../shared/components/loader'
import { useStoryblokTranslationsContext } from '../../context/translations-provider'
import { useStoryblokStories } from '../../hooks/useStoryblokStories'
import { useTranslations } from '../../hooks/useTranslations'
import { findInRelations } from '../../utils/findInRelations'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'

import {
  StContainer,
  StLoadingContainer,
  StMatchCardContainer,
  StMatchResultCardContainer,
} from './MatchCalendar.styled'

import type { StoryblokMatch } from '../../content-types/match'
import type { StoryblokTeam } from '../../content-types/team'
import type { StoryblokImageLink } from '../image-link/ImageLink.types'
import type { ISbStoryData } from '@storyblok/react'

/**
 * Get the content from match stories, and return  matches (reverse) chronologically
 * @param {ISbStoryData<StoryblokMatch>[]} matchStories Storyblok match stories
 * @param {boolean} played Are the matches already played?
 * @returns {StoryBlokMatch[]} An array of the content of matches
 */
const getMatchesContent = (
  matchStories: ISbStoryData<StoryblokMatch>[],
  played: boolean
) => {
  const matchContent = matchStories.map(
    (story: ISbStoryData<StoryblokMatch>) => story.content
  )

  if (matchContent.length <= 0) {
    return []
  }

  const playedMatchContent = matchContent.filter((match) => {
    if (!match || !match.matchday) {
      return false
    }

    const matchHours = Number(match.time?.slice(0, 2))
    const matchMinutes = Number(match.time?.slice(3, 6))

    if (!Number.isNaN(matchHours) && !Number.isNaN(matchMinutes)) {
      const matchDateTime = Dayjs(match.matchday.replace(' ', 'T')).toDate()
      const now = new Date()
      matchDateTime.setHours(matchHours)
      matchDateTime.setMinutes(matchMinutes)

      const matchDateTimeAfterGame = addHours(matchDateTime, 3)

      return played
        ? matchDateTimeAfterGame < now
        : matchDateTimeAfterGame >= now
    } else {
      const matchDate = Dayjs(match.matchday.replace(' ', 'T')).toDate()
      const today = startOfDay(new Date())

      return played ? matchDate < today : matchDate >= today
    }
  })

  if (playedMatchContent.length <= 0) {
    return []
  }
  return playedMatchContent
    .map((match) => ({
      ...match,
      matchday: Dayjs(match.matchday.replace(' ', 'T')).toDate().toString(),
    }))
    .sort((a, b) => {
      const matchDayA = Dayjs(a.matchday).toDate()
      const matchDayB = Dayjs(b.matchday).toDate()
      return played
        ? matchDayB.getTime() - matchDayA.getTime()
        : matchDayA.getTime() - matchDayB.getTime()
    })
}

type StoryblokMatchCalendarProps = {
  blok: {
    sponsor?: StoryblokImageLink[]
    sponsor_live?: StoryblokImageLink[]
    matches: ISbStoryData<StoryblokMatch>[]
  }
  locale: string
  relations: ISbStoryData<StoryblokTeam>[]
}

export const MatchCalendar = ({
  blok,
  locale,
  relations,
}: StoryblokMatchCalendarProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const uuids = useMemo(() => {
    const uuids = blok.matches.map((match) => [
      match.content.home_team,
      match.content.away_team,
    ])

    return [...new Set(uuids.flat())]
  }, [blok.matches])

  const { stories: teams, isLoading: areTeamsLoading } =
    useStoryblokStories<StoryblokTeam>({
      per_page: 100,
      language: locale,
      by_uuids: uuids.join(','),
    })

  const futureMatches = getMatchesContent(blok.matches, false)
  const pastMatches = getMatchesContent(blok.matches, true)

  if (areTeamsLoading) {
    return (
      <StContainer {...storyblokEditable(blok)}>
        <StLoadingContainer>
          <Loader />
        </StLoadingContainer>
      </StContainer>
    )
  }

  return (
    <StContainer {...storyblokEditable(blok)}>
      {blok.matches && blok.matches.length > 0 && (
        <>
          {futureMatches && futureMatches.length > 0 && (
            <StMatchCardContainer>
              {futureMatches.map((match, index) => {
                const league = findInRelations(match?.league, relations ?? [])

                const homeTeam =
                  typeof match.home_team === 'string'
                    ? teams.find((team) => team.uuid === match.home_team)
                    : match.home_team
                const awayTeam =
                  typeof match.away_team === 'string'
                    ? teams.find((team) => team.uuid === match.away_team)
                    : match.away_team

                return (
                  <MatchCard
                    key={match._uid}
                    index={index}
                    time={match.time === '' ? t('tbd') : match.time}
                    date={match.matchday}
                    locale={locale}
                    homeTeam={
                      (homeTeam as ISbStoryData<StoryblokTeam>)?.content
                        ?.name ?? ''
                    }
                    homeTeamLogo={
                      (homeTeam as ISbStoryData<StoryblokTeam>)?.content?.logo
                    }
                    homeTeamStatsId={
                      (homeTeam as ISbStoryData<StoryblokTeam>)?.content
                        ?.stats_data_id
                    }
                    awayTeam={
                      (awayTeam as ISbStoryData<StoryblokTeam>)?.content
                        ?.name ?? ''
                    }
                    awayTeamLogo={
                      (awayTeam as ISbStoryData<StoryblokTeam>)?.content?.logo
                    }
                    awayTeamStatsId={
                      (awayTeam as ISbStoryData<StoryblokTeam>)?.content
                        ?.stats_data_id
                    }
                    leagueName={league?.content?.name}
                    leagueLogo={league?.content?.logo}
                    leagueMatchInfo={match.league_match_info}
                    infoLink={{
                      href: getHrefFromStoryblokLink(match.info) || '',
                    }}
                    ticketLink={{
                      href: getHrefFromStoryblokLink(match.tickets) || '',
                    }}
                    vipLink={{
                      href: getHrefFromStoryblokLink(match.vip) || '',
                    }}
                    sponsorsLink={blok.sponsor?.[0]?.link}
                    sponsorsLogo={blok.sponsor?.[0]?.image}
                    sponsorsLiveLink={blok.sponsor_live?.[0]?.link}
                    sponsorsLiveLogo={blok.sponsor_live?.[0]?.image}
                  />
                )
              })}
            </StMatchCardContainer>
          )}
          {pastMatches && pastMatches.length > 0 && (
            <>
              <Divider title={t('results')}></Divider>
              <StMatchResultCardContainer>
                {pastMatches.map((match) => {
                  const league = findInRelations(match?.league, relations ?? [])

                  const homeTeam =
                    typeof match.home_team === 'string'
                      ? teams.find((team) => team.uuid === match.home_team)
                      : match.home_team
                  const awayTeam =
                    typeof match.away_team === 'string'
                      ? teams.find((team) => team.uuid === match.away_team)
                      : match.away_team

                  return (
                    <MatchResultCard
                      key={match._uid}
                      date={match?.matchday}
                      locale={locale}
                      no_score={t('tbd')}
                      home_team={
                        (homeTeam as ISbStoryData<StoryblokTeam>)?.content
                          ?.name ?? ''
                      }
                      home_team_logo={
                        (homeTeam as ISbStoryData<StoryblokTeam>)?.content?.logo
                      }
                      home_team_goals={match.home_team_goals}
                      homeTeamStatsId={
                        (homeTeam as ISbStoryData<StoryblokTeam>)?.content
                          ?.stats_data_id
                      }
                      away_team={
                        (awayTeam as ISbStoryData<StoryblokTeam>)?.content
                          ?.name ?? ''
                      }
                      away_team_logo={
                        (awayTeam as ISbStoryData<StoryblokTeam>)?.content?.logo
                      }
                      away_team_goals={match.away_team_goals}
                      awayTeamStatsId={
                        (awayTeam as ISbStoryData<StoryblokTeam>)?.content
                          ?.stats_data_id
                      }
                      league_name={league?.content?.name}
                      league_logo={league?.content?.logo}
                      league_match_info={match.league_match_info}
                      report_title={t('report_and_photos')}
                      report_link={getHrefFromStoryblokLink(match.report)}
                    />
                  )
                })}
              </StMatchResultCardContainer>
            </>
          )}
        </>
      )}
    </StContainer>
  )
}
