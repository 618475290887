import { startOfDay } from 'date-fns'

import type { StoryblokMatch } from '../content-types/match'
import type { ISbStoryData } from '@storyblok/react'

/**
 * Get the content from match stories, and return  matches (reverse) chronologically
 * @param {ISbStoryData<StoryblokMatch>[]} matchStories Storyblok match stories
 * @param {boolean} played Are the matches already played?
 * @returns {StoryBlokMatch[]} An array of the content of matches
 */
export const getMatchesContent = (
  matchStories: ISbStoryData<StoryblokMatch>[],
  played: boolean
) => {
  const matchContent = matchStories.map(
    (story: ISbStoryData<StoryblokMatch>) => story.content
  )

  if (matchContent.length <= 0) {
    return []
  }

  const playedMatchContent = matchContent.filter((match) => {
    if (!match || !match.matchday) {
      return false
    }

    const matchDate = new Date(match.matchday.replace(' ', 'T'))
    const startOfToday = startOfDay(new Date())

    return played
      ? matchDate.getTime() < startOfToday.getTime()
      : matchDate.getTime() >= startOfToday.getTime()
  })

  if (playedMatchContent.length <= 0) {
    return []
  }

  return playedMatchContent.sort((a, b) => {
    const matchDayA = new Date(a.matchday.replace(' ', 'T'))
    const matchDayB = new Date(b.matchday.replace(' ', 'T'))
    return played
      ? matchDayB.getTime() - matchDayA.getTime()
      : matchDayA.getTime() - matchDayB.getTime()
  })
}
