import { ATeamStandings } from './a-team-standings'
import { AccountBalance } from './account-balance'
import { AccountData } from './account-data'
import { AccountWelcome } from './account-welcome'
import { ArticlePage } from './article-page'
import { ArticleWidthSectionContainer } from './article-width-section-container'
import { ArticlesOverview } from './articles-overview'
import { Button } from './button'
import { ButtonContainer } from './button-container'
import { Column } from './column'
import { EqualColumns } from './columns-equal'
import {
  ColumnsOneThirdTwoThirds,
  ColumnsTwoThirdsOneThird,
} from './columns-unequal'
import { Divider } from './divider'
import { FullWidthSectionContainer } from './full-width-section-container'
import { GenericEmbed } from './generic-embed'
import { Grid } from './grid'
import { HighlightBanner } from './highlight-banner'
import { ImageLink } from './image-link'
import { MatchCalendar } from './match-calendar'
import { MatchWidget } from './match-widget'
import { MaxWidthSectionContainer } from './max-width-section-container'
import { Page } from './page'
import { PageHeader } from './page-header'
import { PersonCard } from './person-card'
import { PersonCards } from './person-cards'
import { PlayerCard } from './player-card'
import { PlayerCardContainer } from './player-card-container'
import { PlayerStats } from './player-stats'
import { PromoCard } from './promo-card'
import { RelatedArticles } from './related-articles'
import { RichText } from './rich-text'
import { SectionHeader } from './section-header/SectionHeader'
import { Sponsors } from './sponsors'
import { VideoEmbed } from './video-embed'
import { YoungCercleStandings } from './young-cercle-standings'

import type { SbBlokData } from '@storyblok/react'

export const components = {
  column: Column,
  grid: Grid,
  page: Page,
  divider: Divider,
  columns_one_third_two_thirds: ColumnsOneThirdTwoThirds,
  columns_two_thirds_one_third: ColumnsTwoThirdsOneThird,
  two_columns: EqualColumns,
  three_columns: EqualColumns,
  section_header: SectionHeader,
  button: Button,
  promo_card: PromoCard,
  page_header: PageHeader,
  image_link: ImageLink,
  rich_text: RichText,
  article_page: ArticlePage,
  articles_overview: ArticlesOverview,
  sponsors_selection: Sponsors,
  player_card: PlayerCard,
  player_card_container: PlayerCardContainer,
  match_calendar: MatchCalendar,
  button_container: ButtonContainer,
  highlight_banner: HighlightBanner,
  account_welcome: AccountWelcome,
  account_data: AccountData,
  account_balance: AccountBalance,
  latest_articles: RelatedArticles,
  player_articles: RelatedArticles,
  match_articles: RelatedArticles,
  max_width_section_container: MaxWidthSectionContainer,
  article_width_section_container: ArticleWidthSectionContainer,
  full_width_section_container: FullWidthSectionContainer,
  match_widget: MatchWidget,
  a_team_standings: ATeamStandings,
  young_cercle_standings: YoungCercleStandings,
  video_embed: VideoEmbed,
  generic_embed: GenericEmbed,
  player_stats: PlayerStats,
  person_card: PersonCard,
  person_cards: PersonCards,
}

export type StoryblokComponent = {
  blok: SbBlokData
}
