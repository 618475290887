import Image from 'next/image'
import Markdown from 'react-markdown'
import styled, { css } from 'styled-components'

import {
  BodyLargeRegular,
  BodyLargeRegularStyles,
  BodyMediumRegularStyles,
  Heading2,
  Heading3,
  Heading4,
} from '../../../shared/components/typography'
import { helveticaNowDisplaySkewedFont } from '../../../shared/fonts/helveticaNowDisplaySkewed'
import { hypeFont } from '../../../shared/fonts/hype'
import { hypeSkewedFont } from '../../../shared/fonts/hypeSkewed'

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const StMarkdrown = styled(Markdown)`
  > * {
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
`

export const StParagraph = styled(BodyLargeRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

export const StHeading2 = styled(Heading2)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space16};
  width: 100%;
`

export const StHeading3 = styled(Heading3)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  width: 100%;
`

export const StHeading4 = styled(Heading4)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  width: 100%;
`

export const StUl = styled.ul`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  width: 100%;

  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space4};
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  max-width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space8});
`

export const StLi = styled.li`
  ${BodyLargeRegularStyles}
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  list-style-type: '⭓';
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};

  &::marker {
    color: ${({ theme }) => theme.Colors.Primary.Base};
    font-size: 14px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    &::marker {
      font-size: 16px;
    }
  }
`

export const StAnchor = styled.a`
  color: ${({ theme }) => theme.Colors.Primary.Base};

  &:hover {
    text-decoration: none;
  }
`

export const StImage = styled(Image)`
  max-width: 1000px;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: ${({ theme }) => theme.UI.SpacingPx.Space12} 0px;
`

export const StBlockQuote = styled.blockquote`
  width: fit-content;
  max-width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space40});
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space8};
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space6};
  align-self: center;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space9};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space12};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    padding-left: ${({ theme }) => theme.UI.SpacingPx.Space20};
    padding-right: ${({ theme }) => theme.UI.SpacingPx.Space20};
  }

  ${StParagraph}:not(:last-child), ${StParagraph}:first-child {
    ${css`
      ${hypeFont.style}
    `}

    padding: 0;
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.Colors.Primary.Base};

    shape-outside: circle(50%);

    padding-left: ${({ theme }) => theme.UI.SpacingPx.Space7};

    @media screen and (min-width: ${({ theme }) =>
        theme.UI.BreakpointPx.Tablet}) {
      ${css`
        ${hypeSkewedFont.style}
      `}
    }
  }

  ${StParagraph}:first-child:last-child {
    margin-bottom: 0;
  }

  ${StParagraph}:last-child:not(:first-child) {
    ${BodyMediumRegularStyles}
    margin: 0;
    padding-left: ${({ theme }) => theme.UI.SpacingPx.Space6};

    @media screen and (min-width: ${({ theme }) =>
        theme.UI.BreakpointPx.Tablet}) {
      ${css`
        ${helveticaNowDisplaySkewedFont.style}
      `}
    }
  }
`

export const StQuoteContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    transform: skew(-10deg);
  }
`

export const StQuoteLine = styled.div`
  background-color: ${({ theme }) => theme.Colors.Primary.Base};
  width: 3px;

  border-radius: ${({ theme }) => theme.UI.BorderRadiusPx.Small};
`

export const StQuoteContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
