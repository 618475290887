import { format } from 'date-fns'

import { ButtonLink } from '../../../shared/components/button'
import { useSWR } from '../../../shared/hooks/useSWR'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'
import { useMatchStatsQuery } from '../../hooks/useMatchStatsQuery'
import { timestampToLocaleDatestring } from '../../utils/timestampToLocaleDate'
import { MatchClub } from '../match-club'
import { MatchHeader } from '../match-header'
import { MatchLeague } from '../match-league'
import { MatchLiveCard, isValidLink } from '../match-live-card'

import {
  StContainer,
  StDate,
  StMatchday,
  StTime,
  StLeftContainer,
  StCenterContainer,
  StRightContainer,
  StTimeContainer,
} from './MatchCard.styled'

import type {
  Hyperlink,
  MatchInformation,
  StoryblokImage,
  StoryblokLink,
} from 'types'

type MatchCardProps = {
  className?: string
  index: number
  time?: string
  date: string
  homeTeam: string
  homeTeamLogo?: StoryblokImage
  homeTeamStatsId?: string
  awayTeam: string
  awayTeamLogo?: StoryblokImage
  awayTeamStatsId?: string
  leagueName?: string
  leagueLogo?: StoryblokImage
  leagueMatchInfo?: string
  locale: string
  vipLink?: Hyperlink
  infoLink?: Hyperlink
  ticketLink?: Hyperlink
  sponsorsLogo?: StoryblokImage
  sponsorsLink?: StoryblokLink
  sponsorsLiveLogo?: StoryblokImage
  sponsorsLiveLink?: StoryblokLink
}

export const MatchCard = ({
  className,
  index,
  time,
  date,
  homeTeam,
  homeTeamLogo,
  homeTeamStatsId,
  awayTeam,
  awayTeamLogo,
  awayTeamStatsId,
  leagueName,
  leagueLogo,
  leagueMatchInfo,
  locale,
  vipLink,
  infoLink,
  ticketLink,
  sponsorsLogo,
  sponsorsLink,
  sponsorsLiveLogo,
  sponsorsLiveLink,
}: MatchCardProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const query = useMatchStatsQuery({
    homeContestantId: homeTeamStatsId,
    awayContestantId: awayTeamStatsId,
    matchDay: date,
  })

  const { data } = useSWR<MatchInformation>(
    query ? `/stats/matches?${query}` : ''
  )

  if (data?.status === 'Playing') {
    return (
      <MatchLiveCard
        homeTeamName={homeTeam ?? ''}
        homeTeamLogo={homeTeamLogo}
        homeTeamScore={data.score?.home || 0}
        awayTeamName={awayTeam ?? ''}
        awayTeamLogo={awayTeamLogo}
        awayTeamScore={data.score?.away || 0}
        matchDate={date}
        matchTime={
          data?.startDateTime
            ? format(new Date(data.startDateTime), 'HH:mm')
            : time
        }
        sponsorImage={sponsorsLiveLogo}
        sponsorLink={{
          href: getHrefFromStoryblokLink(sponsorsLiveLink) ?? '',
        }}
        ticketLink={ticketLink}
        vipLink={vipLink}
        infoLink={infoLink}
      />
    )
  }

  return (
    <>
      {index === 0 && (
        <MatchHeader
          image={sponsorsLogo}
          link={{
            url: getHrefFromStoryblokLink(sponsorsLink),
            rel: sponsorsLink?.rel,
            title: sponsorsLink?.title,
            target: sponsorsLink?.target,
          }}
        />
      )}
      <StContainer className={className}>
        <StLeftContainer>
          <MatchLeague
            name={leagueName ?? ''}
            logo={leagueLogo}
            info={leagueMatchInfo}
          ></MatchLeague>
        </StLeftContainer>
        <StCenterContainer>
          <MatchClub name={homeTeam} logo={homeTeamLogo} />
          <StMatchday>
            <StDate>
              {timestampToLocaleDatestring(date.toString(), locale)}
            </StDate>
            <StTimeContainer>
              <StTime>
                {data?.startDateTime
                  ? format(new Date(data.startDateTime), 'HH:mm')
                  : time}
              </StTime>
            </StTimeContainer>
          </StMatchday>
          <MatchClub name={awayTeam} logo={awayTeamLogo} />
        </StCenterContainer>
        <StRightContainer>
          {vipLink && isValidLink(vipLink) && (
            <ButtonLink
              variant="secondary"
              href={vipLink.href}
              size="small"
              gaEventLabel="press_calender_match_vip"
            >
              {t('match_card_vip')}
            </ButtonLink>
          )}
          {infoLink && isValidLink(infoLink) && (
            <ButtonLink
              variant="secondary"
              href={infoLink.href}
              size="small"
              gaEventLabel="press_calender_match_info"
            >
              {t('match_card_info')}
            </ButtonLink>
          )}
          {ticketLink && isValidLink(ticketLink) && (
            <ButtonLink
              variant="primary"
              prefixIcon="ticket"
              href={ticketLink.href}
              size="small"
              gaEventLabel="press_calender_match_tickets"
            >
              {t('match_card_tickets')}
            </ButtonLink>
          )}
        </StRightContainer>
      </StContainer>
    </>
  )
}
