import styled from 'styled-components'

import { CercleCircle } from '../../../shared/components/cercle-circle'
import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodySmallBold,
  Heading1,
  Tag,
} from '../../../shared/components/typography'

export const StContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const StTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  text-align: center;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StTitleContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const StTitle = styled(BodySmallBold)`
  margin-right: auto;
  color: ${({ theme }) => theme.Colors.Neutrals.Black};
`

export const StSponsorLink = styled.a`
  margin-left: auto;
`

export const StSponsorImage = styled(StoryblokServiceImage)`
  max-width: 150px;
  max-height: 36px;
  object-fit: contain;
  margin-left: auto;
`

export const StFullCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  height: 100%;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StCercleCirlce = styled(CercleCircle)`
  position: absolute;
  bottom: 200px;
  left: -50px;
  transform: scale(2);
  fill: ${({ theme }) => theme.Colors.Neutrals.Lightest};
`

export const StResultContainer = styled.div`
  display: flex;
  z-index: 2;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space12} 0;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StMatchday = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;
  text-align: center;
`

export const StLiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme }) => theme.Colors.Semantic.Error};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space1} ${theme.UI.SpacingPx.Space2}`};
  transform: skew(-10deg);
`

export const StLiveBlink = styled.div`
  transform: skew(10deg);
  border-radius: 50%;
  width: ${({ theme }) => theme.UI.SpacingPx.Space3};
  height: ${({ theme }) => theme.UI.SpacingPx.Space3};
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;

  @keyframes blinker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

export const StLiveTitle = styled(Tag)`
  transform: skew(10deg);
`

export const StLiveScore = styled(Heading1)`
  color: ${({ theme }) => theme.Colors.Neutrals.Black};
`

export const StButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
`
