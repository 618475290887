import { faRight } from '@fortawesome/pro-solid-svg-icons/faRight'
import { subHours, subDays, intervalToDuration } from 'date-fns'
import { useRouter } from 'next/router'

import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StArticleTitle,
  StBackgroundContainer,
  StContainer,
  StContentContainer,
  StExtraInfoContainer,
  StIcon,
  StImage,
  StLinkContainer,
  StLinkCta,
  StSlantedEdge,
  StTimestamp,
} from './ArticleCard.styled'

import type { StoryblokImage } from 'types'

interface Props {
  className?: string
  image?: StoryblokImage
  title: string
  href: string
  publishDate: Date
}

export const ArticleCard = ({
  className,
  image,
  publishDate,
  title,
  href,
}: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { locale } = useRouter()

  let timeStampText = ''

  const now = new Date()
  const duration = intervalToDuration({ start: publishDate, end: now })

  if (subHours(now, 1).getTime() < publishDate.getTime()) {
    timeStampText = `${duration.minutes} ${
      duration.minutes === 1 ? t('minute') : t('minutes')
    } ${t('ago')}`
  } else if (subDays(now, 1).getTime() < publishDate.getTime()) {
    timeStampText = `${duration.hours} ${
      duration.hours === 1 ? t('hour') : t('hours')
    } ${t('ago')}`
  } else {
    timeStampText = publishDate.toLocaleDateString(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  return (
    <StContainer className={className} href={href} title={title}>
      {image && (
        <StImage
          quality={100}
          image={image}
          sizeMobile="150px"
          sizeTablet="50vw"
          sizeDesktop="50vw"
        />
      )}

      <StBackgroundContainer>
        <StSlantedEdge />
        <StContentContainer>
          <StArticleTitle>{title}</StArticleTitle>
          <StExtraInfoContainer>
            <StLinkContainer>
              <StLinkCta>
                {t('read_article_cta')} <StIcon icon={faRight} />
              </StLinkCta>
            </StLinkContainer>
            <StTimestamp>{timeStampText}</StTimestamp>
          </StExtraInfoContainer>
        </StContentContainer>
      </StBackgroundContainer>
    </StContainer>
  )
}
