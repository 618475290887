import { ButtonLink } from '../../../shared/components/button'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { MatchClub } from '../match-club'

import {
  StContainer,
  StTitle,
  StTitleContainer,
  StFullCardContainer,
  StCercleCirlce,
  StResultContainer,
  StMatchday,
  StTitleContent,
  StButtonContainer,
  StSponsorImage,
  StSponsorLink,
  StLiveContainer,
  StLiveBlink,
  StLiveTitle,
  StLiveScore,
} from './MatchLiveCard.styled'

import type { Hyperlink, StoryblokImage } from 'types'

interface MatchLiveCardProps {
  className?: string
  title?: string
  homeTeamName: string
  homeTeamLogo?: StoryblokImage
  homeTeamScore?: number
  awayTeamName: string
  awayTeamLogo?: StoryblokImage
  awayTeamScore?: number
  matchDate: string
  matchTime?: string
  sponsorImage?: StoryblokImage
  sponsorLink: Hyperlink
  ticketLink?: Hyperlink
  infoLink?: Hyperlink
  vipLink?: Hyperlink
  daysShorthand?: string
  locale?: string
}

export const isValidLink = (link: Hyperlink | string) => {
  if (typeof link === 'string') {
    return (
      link &&
      link !== '' &&
      link !== '/en/' &&
      link !== '/nl' &&
      link !== '/fr/' &&
      link !== '#'
    )
  }
  return (
    link &&
    link.href !== '' &&
    link.href !== '/en/' &&
    link.href !== '/nl' &&
    link.href !== '/fr/' &&
    link.href !== '#'
  )
}

export const MatchLiveCard = ({
  className,
  homeTeamName,
  homeTeamLogo,
  homeTeamScore = 0,
  awayTeamName,
  awayTeamLogo,
  awayTeamScore = 0,
  matchTime,
  sponsorImage,
  sponsorLink,
  ticketLink,
  infoLink,
  vipLink,
}: MatchLiveCardProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  return (
    <StContainer className={className}>
      <StTitleContainer>
        <StTitleContent>
          <StTitle>
            {t('match_card_started_at')} {matchTime}
          </StTitle>
        </StTitleContent>
        <StTitleContent></StTitleContent>
        <StTitleContent>
          {sponsorImage &&
            (sponsorLink && isValidLink(sponsorLink) ? (
              <StSponsorLink href={sponsorLink.href}>
                <StSponsorImage image={sponsorImage} width={150} height={36} />
              </StSponsorLink>
            ) : (
              <StSponsorImage image={sponsorImage} width={150} height={36} />
            ))}
        </StTitleContent>
      </StTitleContainer>
      <StFullCardContainer>
        <StResultContainer>
          <MatchClub
            variant={'light'}
            name={homeTeamName}
            logo={homeTeamLogo}
          />
          <StMatchday>
            <StLiveContainer>
              <StLiveBlink></StLiveBlink>
              <StLiveTitle>{t('match_card_live')}</StLiveTitle>
            </StLiveContainer>
            <StLiveScore>
              {homeTeamScore} - {awayTeamScore}
            </StLiveScore>
          </StMatchday>
          <MatchClub
            variant={'light'}
            name={awayTeamName}
            logo={awayTeamLogo}
          />
        </StResultContainer>
        <StCercleCirlce variation={3} />
        <StButtonContainer>
          {ticketLink && isValidLink(ticketLink) && (
            <ButtonLink
              variant="primary"
              prefixIcon="ticket"
              href={ticketLink.href}
              size="small"
              gaEventLabel="press_live_match_tickets"
            >
              {t('match_card_tickets')}
            </ButtonLink>
          )}

          {infoLink && isValidLink(infoLink) && (
            <ButtonLink
              variant="secondary"
              href={infoLink.href}
              size="small"
              gaEventLabel="press_live_match_info"
            >
              {t('match_card_info')}
            </ButtonLink>
          )}
          {vipLink && isValidLink(vipLink) && (
            <ButtonLink
              variant="secondary"
              href={vipLink.href}
              gaEventLabel="press_live_match_vip"
              size="small"
            >
              {t('match_card_vip')}
            </ButtonLink>
          )}
        </StButtonContainer>
      </StFullCardContainer>
    </StContainer>
  )
}
