import Image from 'next/image'

interface Props {
  className?: string
}

export const LoaderGif = ({ className }: Props) => {
  return (
    <Image
      width={680}
      height={680}
      priority
      className={className}
      src="https://a.storyblok.com/f/204125/640x640/50e00cf61a/spinner.gif"
      alt="Loading animation"
      unoptimized
    />
  )
}
