import { isTeamCercle } from 'utils'

import { ButtonLink } from '../../../shared/components/button'
import { useSWR } from '../../../shared/hooks/useSWR'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { useMatchStatsQuery } from '../../hooks/useMatchStatsQuery'
import { timestampToLocaleDatestring } from '../../utils/timestampToLocaleDate'
import { MatchClub } from '../match-club'
import { MatchLeague } from '../match-league'
import { isValidLink } from '../match-live-card'

import {
  StContainer,
  StDate,
  StMatchday,
  StTime,
  StTimeContainer,
  StLeftContainer,
  StCenterContainer,
  StRightContainer,
  StScore,
} from './MatchResultCard.styled'

import type { MatchInformation, StoryblokImage } from 'types'

type MatchResultCardProps = {
  className?: string
  date: string
  home_team: string
  home_team_logo?: StoryblokImage
  home_team_goals?: string
  homeTeamStatsId?: string
  away_team: string
  away_team_logo?: StoryblokImage
  away_team_goals?: string
  awayTeamStatsId?: string
  league_name?: string
  league_logo?: StoryblokImage
  league_match_info?: string
  locale: string
  no_score: string
  report_title?: string
  report_link?: string
}

export const MatchResultCard = ({
  className,
  date,
  home_team,
  home_team_logo,
  home_team_goals,
  homeTeamStatsId,
  away_team,
  away_team_logo,
  away_team_goals,
  awayTeamStatsId,
  league_name,
  league_logo,
  league_match_info,
  locale,
  no_score,
  report_title,
  report_link,
}: MatchResultCardProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const query = useMatchStatsQuery({
    homeContestantId: homeTeamStatsId,
    awayContestantId: awayTeamStatsId,
    matchDay: date,
  })

  const { data } = useSWR<MatchInformation>(
    query ? `/stats/matches?${query}` : ''
  )

  return (
    <StContainer className={className}>
      <StLeftContainer>
        <MatchLeague
          name={league_name ?? ''}
          logo={league_logo}
          info={league_match_info}
        ></MatchLeague>
      </StLeftContainer>
      <StCenterContainer>
        <MatchClub name={home_team} logo={home_team_logo} />
        <StMatchday>
          <StDate>{timestampToLocaleDatestring(date, locale)}</StDate>
          {(home_team_goals && away_team_goals) || data?.score ? (
            <StTimeContainer>
              <StTime>
                <StScore isCercle={isTeamCercle(home_team)}>
                  {data?.score?.home === undefined
                    ? home_team_goals
                    : data.score.home}
                </StScore>{' '}
                -{' '}
                <StScore isCercle={isTeamCercle(away_team)}>
                  {data?.score?.away === undefined
                    ? away_team_goals
                    : data.score.away}
                </StScore>
              </StTime>
            </StTimeContainer>
          ) : (
            <StTimeContainer>
              <StTime>{no_score}</StTime>
            </StTimeContainer>
          )}
        </StMatchday>
        <MatchClub name={away_team} logo={away_team_logo} />
      </StCenterContainer>
      <StRightContainer>
        {report_link && isValidLink(report_link) && (
          <ButtonLink variant="secondary" size="small" href={report_link}>
            {report_title ?? t('match_card_report')}
          </ButtonLink>
        )}
      </StRightContainer>
    </StContainer>
  )
}
