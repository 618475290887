import { useMemo } from 'react'

import {
  getStoryblokDateString,
  getValidStoryblokDate,
} from '../../storyblok/utils/checkStoryblokDate'

export const useMatchStatsQuery = ({
  homeContestantId,
  awayContestantId,
  matchDay,
}: {
  homeContestantId?: string
  awayContestantId?: string
  matchDay: string
}) => {
  return useMemo(() => {
    const matchDate = getValidStoryblokDate(matchDay)

    const matchDateString = getStoryblokDateString(matchDate)

    if (!homeContestantId || !awayContestantId || !matchDate) {
      return
    }

    return new URLSearchParams({
      homeContestantId,
      awayContestantId,
      matchDate: matchDateString,
    }).toString()
  }, [awayContestantId, homeContestantId, matchDay])
}
